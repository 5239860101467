/**
 * @generated SignedSource<<879c1be10bcbca1f7ee13d18cfde0093>>
 * @relayHash 2045358683dda1c1d9e8850837747b23
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/483.0.0-2024-10-09T12:58:41.689Z/RecentlyViewedItemsCarouselViewerRefetchQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentlyViewedItemsCarouselViewerRefetchQuery$variables = {
  loadPortfolioData?: boolean | null;
  selectedItemIds?: ReadonlyArray<string | null> | null;
  userIds?: ReadonlyArray<string | null> | null;
};
export type RecentlyViewedItemsCarouselViewerRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsCarousel_viewer">;
  };
};
export type RecentlyViewedItemsCarouselViewerRefetchQuery = {
  response: RecentlyViewedItemsCarouselViewerRefetchQuery$data;
  variables: RecentlyViewedItemsCarouselViewerRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "loadPortfolioData"
  },
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "selectedItemIds"
  },
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "userIds"
  }
],
v1 = {
  "kind": "Variable",
  "name": "userIds",
  "variableName": "userIds"
},
v2 = {
  "kind": "Literal",
  "name": "portfolioTypes",
  "value": [
    "HEART"
  ]
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "serviceId",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecentlyViewedItemsCarouselViewerRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "loadPortfolioData",
                "variableName": "loadPortfolioData"
              },
              {
                "kind": "Variable",
                "name": "selectedItemIds",
                "variableName": "selectedItemIds"
              },
              (v1/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "RecentlyViewedItemsCarousel_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecentlyViewedItemsCarouselViewerRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "loadPortfolioData",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "itemIds",
                    "variableName": "selectedItemIds"
                  },
                  (v2/*: any*/),
                  (v1/*: any*/)
                ],
                "concreteType": "PortfolioItemV2",
                "kind": "LinkedField",
                "name": "portfolioItemMatches",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portfolioId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portfolioItemId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Item",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "userPortfolios",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  (v2/*: any*/),
                  (v1/*: any*/)
                ],
                "concreteType": "PortfoliosConnection",
                "kind": "LinkedField",
                "name": "portfolios",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfoliosEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/483.0.0-2024-10-09T12:58:41.689Z/RecentlyViewedItemsCarouselViewerRefetchQuery",
    "metadata": {},
    "name": "RecentlyViewedItemsCarouselViewerRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e575f2f6f4fc301f2c50dde7f6a1c733";

export default node;
